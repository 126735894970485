import styled from '@emotion/styled';
import CarouselFeedBlock from '@cont/FeedTypeBlock/CarouselFeedBlock';
import { mediaQuery, Spacings } from '@styles/index';
import { HOME_PAGE } from '@constants/appFlyerLinks';
import useLegendsBanner from '@api/hooks/home/useLegendsBanner';
import useCarouselData from '@api/hooks/home/useCarouselData';
import { useEffect, useState } from 'react';
import { bannerStates } from '@constants/index';
import { segment } from '@actions/analytics';
import useOfflineCentreBannerData from '@api/hooks/home/useOfflineCenterBannerData';
import OfflineCentreBanner from '@comp/OfflineCentreBanner/OfflineCentreBanner';
import Divider from 'aquilla/core/atoms/Divider';
import Dynamic from '@base/Dynamic/Dynamic';
import { useIsMobile } from '@stores/AppCommonData';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import Stats from './Stats';
import HomeContainer from './HomeContainer';

const AppInstallBanner = Dynamic(() => import('./AppInstallBanner'));
const BannerSection = Dynamic(() => import('./BannerSection'));
const PresubscribedLegendsSection = Dynamic(() =>
  import('../PreSubscribed/PresubscribedLegendsSection')
);

const Container = styled.div`
  background: var(--color-base-1);
  overflow: hidden;
  /* padding-top: ${Spacings.SPACING_18B}; */
  scroll-behavior: smooth;
  ${mediaQuery.uptoMobile} {
    /* padding-top: ${Spacings.SPACING_12B}; */
  }

  ${mediaQuery.onlyTablet} {
    padding: ${Spacings.SPACING_8B};
  }
`;

const Wrapper = styled.div`
  width: 1136px;
  margin: 84px auto 160px;
  ${mediaQuery.uptoTablet} {
    width: 728px;
    margin: 52px auto 96px;
  }
  ${mediaQuery.uptoMobile} {
    width: 100%;
    margin: 95px 0 55px;
  }
`;

const CarouselFeedWrapper = styled.div`
  margin: ${Spacings.SPACING_8B} auto 0;
  width: 1136px;

  ${mediaQuery.uptoMobile} {
    width: calc(100vw - 32px);
  }

  [class*='BannerContainer'] {
    width: 1136px;
    ${mediaQuery.uptoMobile} {
      width: calc(100vw - 32px);
    }
    img {
      width: 100%;
      height: 88px;
      ${mediaQuery.uptoMobile} {
        height: 148px;
      }
    }
  }

  [class*='ControlButton'] {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Homepage = ({ hasPmBanner }) => {
  const { legendsBanner = [] } = useLegendsBanner();
  const { carouselData = [] } = useCarouselData();
  const [showBanner, setShowBanner] = useState(false);
  const isMobile = useIsMobile();
  const { offlineCentreBanner = {} } = useOfflineCentreBannerData({
    pageType: 'root-page',
    itemUID: 'root-page'
  });
  const handleScroll = () => {
    if (!showBanner) {
      setShowBanner(true);
    }
  };

  const pmBannerClickHandler = (element) => (e) => {
    e.preventDefault();

    document.querySelector(element.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });

    segment.track('Banner - Banner Clicked');
  };
  useEffect(() => {
    const anchorNodes = document.querySelectorAll('a[href^="#"]');
    anchorNodes.forEach((anchor) => {
      anchor.addEventListener('click', pmBannerClickHandler(anchor));
    });

    return () => {
      anchorNodes?.forEach((anchor) => {
        anchor.removeEventListener('click', pmBannerClickHandler(anchor));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isMobile) {
        document.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <Container>
      <Divider />
      {hasPmBanner ? (
        <CarouselFeedWrapper>
          {!!carouselData?.[0]?.data && (
            <CarouselFeedBlock
              block={{
                ...carouselData[0],
                // Process data for backward compatibility with CarouselFeedBlock component.
                carouselFeed: carouselData[0].data
                  .filter((item) => item.bannerType === 0)
                  .map((item) => ({
                    course: [
                      bannerStates.LEGENDS,
                      bannerStates.TEST_SERIES,
                      bannerStates.GENERIC,
                      bannerStates.COMBAT,
                      bannerStates.RENEWAL
                    ].includes(item.bannerType)
                      ? item.sessionInfo
                      : item.sessionInfo?.courseInfo,
                    sessionData: item?.sessionInfo,
                    bannerDetails: {
                      uid: item.uid,
                      title: item.title,
                      description: item.description,
                      type: item.bannerType,
                      bannerPhoto: item.bannerPhoto,
                      bannerAppPhoto: item.bannerAppPhoto,
                      clickURL: item.clickUrl
                    }
                  }))
              }}
              dataAnalytics={() => {}}
              enroll={() => {}}
              preSubscribed
              gridColumn="span 24"
              itemWidth={1136}
              itemHeight={88}
              imgWidth="52%"
              leftOverlay="200px"
            />
          )}
        </CarouselFeedWrapper>
      ) : null}
      <HomeContainer />
      <OfflineCentreBanner data={offlineCentreBanner} />
      <Stats />
      <Wrapper>
        <SecondSection />
        {(legendsBanner || []).map((block) => {
          if (block.type === 'legends_on_unacademy')
            return <PresubscribedLegendsSection block={block} />;
          if (block.type === 'generic_banner')
            return <BannerSection block={block} />;
          return '';
        })}
        <ThirdSection />
        <FourthSection />
      </Wrapper>
      {showBanner && (
        <AppInstallBanner
          pageType="homePageDownloadBanner"
          downloadLink={HOME_PAGE}
        />
      )}
    </Container>
  );
};

export default Homepage;
